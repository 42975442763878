<template>
  <div class="m1">
    <div class="hd ">
      <div class="h1">
        告警信息
      </div>
      <div class="back" @click="back()">
        返回
      </div>
    </div>
    <sbxq :hjjc_current="hjjc_current" :sbId="sbId"></sbxq>
  </div>
</template>

<script>
import sbxq from '/src/views/SBGL/sbxxglDetail2.vue'
export default {
  name: 'gsgly',
  components: {

    sbxq
  },
  data() {
    return {
      list: [{
        name: '在线检测',
        qr: false
      },
      {
        name: '项目管理',
        qr: false
      }, {
        name: '设备管理',
        qr: false
      }, {
        name: '施工管理',
        qr: false
      }, {
        name: '告警管理',
        qr: false
      }, {
        name: '账号管理',
        qr: false
      }
      ],
      value: true,
      currentPage4: 1,
      obj: {
        "page": 1,
        "size": 10
      },
      Data: [],
      total: null,
      sbId: null,
      hjjc_current: null
    }
  },
  created() {
    console.log(this.$route.query.id, this.$route.query.type, 'this.$route.query.id！！！');
    this.sbId = parseInt(this.$route.query.id)
    this.hjjc_current = parseInt(this.$route.query.type)
    this.info()
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    del(id) {
      console.log(id);
      this.$ajax('delsgry', 'delete', id).then(res => {
        console.log(res);
        this.info()
        this.$message({
          type: 'success',
          message: '删除成功'
        });
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.obj.size = val
      this.info()

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.obj.page = val
      this.info()
    },
    info() {
      this.$ajax('gjxx', 'post', this.obj).then(res => {
        console.log(res);
        this.Data = res.data.records
        this.total = res.data.total
      })
    }
  },

}
</script>

<style lang="scss" scoped>
.m1 {
  position: relative;
  color: #404040;
  width: 100%;

  // height: 59.4rem;

  .hd {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;

    .h1 {
      display: inline-block;
      width: 11.5rem;
      height: 3rem;
      line-height: 3rem;
      padding-left: 1rem;
      font-size: 1.4rem;
    }

    .back {
      border: 1px solid #2483d6;
      border-radius: 0.5rem;
      width: 3rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      color: #2483d6;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
</style>
